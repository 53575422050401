<template>
  <div class="complaintAcceptance">
    <rxNavBar title="投诉受理"></rxNavBar>
    <loading v-if="loadingFlag"></loading>

    <div class="complaintInfo">投诉信息</div>
    <div class="complaintInfoList">
      <div class="roomDetailedAddress">
        <div class="roomAddress">{{ roomDetailedAddress }}</div>
        <img src="../../assets/images/Editing.png" class="edit_icon" @click="goHouse">
      </div>
      <div class="line"></div>
      <div class="listLeft">
        <div class="list">投诉人：<span class="sp">{{ complaintUserName }}</span></div>
      </div>
      <div class="listRight">
        <div class="list">联系方式：<span class="sp">{{ complaintMobile }}</span></div>
      </div>
      <div class="label">投诉时间： <span style="color: #ff5809;font-size: 13px">{{ complaintTime }}</span></div>
      <div class="label">投诉原因：<span class="sp">{{ complaintDetail }}</span></div>
    </div>
    <div class="acceptanceInfo">受理情况</div>
    <!--        受理人员-->

    <div id="acceptance" class="part-inputpart-row" @click="goPerson(0)">
      <span :class="!acceptor.id ? 'maker':'orange'">• </span>
      <span class="part-inputpart-row-header">受理人员</span>
      <span class="content-divide">|</span>
      <span class="part-inputpart-row-graytext"
            :class="acceptor ? 'part-inputpart-row-normaltext':'' ">{{ acceptor.name }}</span>
      <img class="part-inputpart-row-right part-inputpart-row-right-rightArrow"
           src="../../assets/images/youjiantou.png">
    </div>

    <!--投诉类型-->
    <div id="complaintType" class="part-inputpart-row" @click="isTypeShow = !isTypeShow">
      <span :class="!selectType.dictionaryTitle? 'maker':'orange'">• </span>
      <span class="part-inputpart-row-header">投诉类型</span>
      <span class="content-divide">|</span>
      <span class="part-inputpart-row-graytext"
            :class="selectType.dictionaryTitle ? 'part-inputpart-row-normaltext':'' ">{{
          selectType.dictionaryTitle ? selectType.dictionaryTitle : '请选择投诉类型'
        }}</span>
      <img class="part-inputpart-row-right"
           :class=" isTypeShow ? 'complaintTypedropDownList-upArrow' : 'complaintTypedropDownList-downArrow' "
           src="../../assets/images/triangle.png">
    </div>
    <!--        类型选项-->
    <!--        <div>-->
    <!--            <div class="complaintTypedropDownList" v-if="isTypeShow">-->
    <!--                <div class="complaintTypedropDownList-option" :class="item==selectType ? 'optionSelectedColor' : '' " v-for="(item,index) in complaintTypeArr" :key="index" @click="selectTypeOption(index,$event)">{{item}}</div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        受理时间-->
    <div id="checkInDatePanel" class="part-inputpart-row" @click="clickCheckInDateShow">
      <span :class="!checkInDate ? 'maker':'orange'">• </span>
      <span class="part-inputpart-row-header">受理时间</span>
      <span class="content-divide">|</span>
      <span class="part-inputpart-row-graytext"
            :class="{'part-inputpart-row-normaltext':checkInDate}">{{ checkInDate | formatDateTime }}</span>
      <img class="part-inputpart-row-right-timepick" src="../../assets/images/timepick.png">
    </div>
    <div>
      <van-popup v-model="isCheckInDateShow" position="bottom">
        <van-datetime-picker
            v-model="currentDate"
            type="datetime"
            title="选择年月日"
            item-height="35px"
            @confirm="checkInDateConfirm"
            @cancel="checkInDateCancel"
        />
      </van-popup>
    </div>
    <div class="part-inputpart-row" @click="isNameShow = !isNameShow">
      <span :class="!selectName.dictionaryTitle? 'maker':'orange'">• </span>
      <span class="part-inputpart-row-header">投诉等级</span>
      <span class="content-divide">|</span>
      <span class="part-inputpart-row-graytext"
            :class="selectName.dictionaryTitle ? 'part-inputpart-row-normaltext':'' ">{{
          selectName.dictionaryTitle ? selectName.dictionaryTitle : '请选择投诉等级'
        }}</span>
      <img class="part-inputpart-row-right"
           :class=" isNameShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
           src="../../assets/images/triangle.png">
    </div>
    <div class="part-inputpart-row" @click="goDepartment">
      <!--      <span :class="isTypeSelect == ''? 'maker':'orange'">• </span>-->
      <span class="part-inputpart-row-header" style="margin-left: 12px">投诉部门</span>
      <span class="content-divide">|</span>
      <span class="part-inputpart-row-graytext"
            :class="complaintDepartment ? 'part-inputpart-row-normaltext':'' ">{{ complaintDepartment.title || '' }}</span>
      <img class="part-inputpart-row-right part-inputpart-row-right-rightArrow"
           src="../../assets/images/youjiantou.png">
    </div>
    <!--        投诉对象-->
    <div class="part-inputpart-row" @click="goPerson(1)">
      <!--      <span :class="inputValue == '' ? 'maker':'orange'">• </span>-->
      <span class="part-inputpart-row-header" style="margin-left: 12px">投诉对象</span>
      <span class="content-divide">|</span>
      <span class="part-inputpart-row-graytext"
            :class="respondent ? 'part-inputpart-row-normaltext':'' ">{{ respondent.name }}</span>
      <img class="part-inputpart-row-right part-inputpart-row-right-rightArrow"
           src="../../assets/images/youjiantou.png">
    </div>
    <!--        受理备注-->
    <div class="row2">
      <div class="acceptanceRemake">受理备注<span class="sp">(非必填)</span></div>
      <textarea placeholder="输入受理备注" class="remake" v-model="remarks"/>

      <van-uploader class="uploadDiv"
                    image-fit="contain"
                    multiple
                    :max-count="10"
                    :after-read="afterReadTest"
                    v-model="fileList">
        <div class="upload">
          <img src="../../assets/images/grayAdd.png" class="upload_icon">
          <div class="upload_font">上传图片</div>
        </div>
      </van-uploader>

    </div>

    <van-button
        :class="!(id && acceptor.id && checkInDate && selectType.id && selectName.id && setHouse_id) ? 'saveButton_Disable':'saveButton_Enable'"
        :disabled="!(id && acceptor.id && checkInDate && selectType.id && selectName.id && setHouse_id)"
        @click="save"
    >
      提交
    </van-button>
    <!--        受理记录-->

    <div class="part" v-if="acceptanceLists&&acceptanceLists.length">
      <span class="part-subtitle">受理记录</span>
      <div v-for="(item,index) in acceptanceLists" :key="index">
        <div class="traversalRecord">
          <div class="redRing">
            <div v-if="0==index" class="redVertical-upline"></div>
            <div v-if="index!=acceptanceLists.length-1" class="redVertical-downline"></div>
            <div class="redDot"></div>
          </div>
        </div>
        <div class="traversalRecord-inputpart-column">
          <div class="otherExpenses-inputpart-row-bottom">
            <span class="part-inputpart-row-header">{{item.complaintLevelStr}}</span>
            <span class="part-inputpart-row-graytime">{{item.addTime}}</span>
          </div>
          <div class="otherExpenses-inputpart-row-bottom">
            <span class="part-inputpart-row-normaltext">{{item.staffName}}</span>
            <pre>  </pre>
            <span style="text-overflow: ellipsis;overflow: hidden;" class="part-inputpart-row-graytext">{{item.remarks}}</span>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="isNameShow" position="bottom">
      <van-picker
          show-toolbar
          :columns="acceptanceArr"
          @cancel="isNameShow = false"
          :default-index="acceptanceIndex"
          value-key="dictionaryTitle"
          @confirm="selectNameOption"
      />
    </van-popup>
    <van-popup v-model="isTypeShow" position="bottom">
      <van-picker
          show-toolbar
          :columns="complaintTypeArr"
          @cancel="isTypeShow = false"
          :default-index="complaintTypeIndex"
          value-key="dictionaryTitle"
          @confirm="selectTypeOption"
      />
    </van-popup>

  </div>
</template>

<script>
import {
  NavBar,
  Button,
  DatetimePicker,
  Uploader, Picker, Popup,
} from "vant";
import rxNavBar from "../../components/rongxun/rx-navBar/navBar"
import eventBus from "../../components/rongxun/rx-navBar/envbus";
import {
  afterRentComplaintAcceptInit,
  queryComplaintHistory,
  queryBaseData, saveAfterRentComplaintAccept, base64Upload
} from "../../getData/getData";
import {getStaffId, globaluserId, responseUtil} from "../../libs/rongxunUtil";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [DatetimePicker.name]: DatetimePicker,
    [Uploader.name]: Uploader,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    rxNavBar

  },
  name: "complaintAcceptance",
  data() {
    return {
      historyList: [],
      loadingFlag: false,//上传图片loading
      remarks: '',
      person: '',
      acceptor: {},
      respondent: {},
      complaintDepartment: {},
      id: this.$route.query.id || '',
      acceptanceIndex: '',
      complaintTypeIndex: '',
      fileList: [],
      acceptanceLists: [],
      roomDetailedAddress: '',
      roomHouse_id: '',
      setHouse_id: '',
      complaintUserName: '',
      complaintMobile: '',
      complaintTime: '',
      complaintDetail: '',
      selectName: {},
      selectType: {},
      isNameShow: false,
      isTypeShow: false,
      isNameSelect: '',
      isTypeSelect: '',
      inputValue: '',
      icon: [
        {first: '../../assets/images/potion_icon.png'},
        {last: '../../assets/images/potion_icon2.png'},
      ],
      acceptanceArr: [],
      complaintTypeArr: [],
      //受理日期
      isCheckInDateShow: false,
      currentDate: new Date(),
      checkInDate: new Date(),
    }
  },
  created() {
    this.initData()
    this.initDictionaryData()
  },
  beforeRouteLeave: function (to, from, next) {
    if (from && to.name != 'referrer' && to.name != 'SelectRoom' && to.name != 'SelectDepartment') {//此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
      if (this.$vnode && this.$vnode.data.keepAlive) {
        if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache) {
          if (this.$vnode.componentOptions) {
            var key = this.$vnode.key == null
                ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '')
                : this.$vnode.key;
            var cache = this.$vnode.parent.componentInstance.cache;
            var keys = this.$vnode.parent.componentInstance.keys;
            if (cache[key]) {
              if (keys.length) {
                var index = keys.indexOf(key);
                if (index > -1) {
                  keys.splice(index, 1);
                }
              }
              delete cache[key];
            }
          }
        }
      }
      this.$destroy();
    }
    next();
  },
  //使用keep-alive后刷新数据
  activated() {
    eventBus.$on('referrer', function (data) {
      if (data.signPersonInfo.staffName && data.signPersonInfo.id && this.person == 0) {
        //受理人员
        this.$set(this.acceptor, 'name', data.signPersonInfo.staffName)
        this.$set(this.acceptor, 'id', data.signPersonInfo.id)
      }
      if (data.signPersonInfo.staffName && data.signPersonInfo.id && this.person == 1) {
        //投诉对象
        this.$set(this.respondent, 'name', data.signPersonInfo.staffName)
        this.$set(this.respondent, 'id', data.signPersonInfo.id)
      }

    }.bind(this));
    eventBus.$on('SelectRoom', function(data){
      if(data .searchInfo && data.setHouseId){
        this.roomDetailedAddress = data.searchInfo
        this.setHouse_id = data.setHouseId
        this.roomHouse_id = data.searchId
      }
    }.bind(this));
    eventBus.$on('SelectDepartment', function(data){
      if(data.title && data.id){
        this.complaintDepartment = data
      }
    }.bind(this));
  },

  filters: {
    formatDateTime(value) {
      if (!value) return
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
      //return y + '-' + MM + '-' + d
    }
  },

  methods: {

    goHouse() {
      this.$router.push({
        name: 'SelectRoom',
        query: {
          jumpType: "complaintAcceptance",
        }
      });
    },
    //图片多选上传
    afterReadTest(file){
      // this.loadingFlag = true;
      let fileLength = parseInt(file.length) - parseInt(1)
      if(file.length > 1){
        for(let i = 0; i<file.length;i++){
          let fileDetail = file[i];
          this.afterRead(fileDetail,i,fileLength);
        }
      }else{
        this.afterRead(file);
      }
    },

    afterRead(file,i,fileLength) {
      let that = this
      let initData = {
        base64 : file.content
      }
      that.loadingFlag = true;
      base64Upload(initData).then(function (response) {
        that.loadingFlag = false;
        responseUtil.dealResponse(that, response, () => {
          file.url = response.data.data.path
          console.log(that.picList)
          if(i == fileLength){
            that.loadingFlag = false
          }
        })
      })
    },

    save() {
      let that = this
      let data = {}
      data.user_id = globaluserId()
      //投诉id
      data.complaintId = that.id
      //受理人
      data.acceptStaff_id = that.acceptor.id
      //受理时间
      data.acceptTime = that.checkInDate
      //投诉类型
      data.complaintType_id = that.selectType.id
      //投诉等级
      data.complaintLevel_id = that.selectName.id
      //投诉对象用户id
      data.complaintTargetGlobaluser_id = that.respondent.id
      //投诉对象部门id
      data.complaintTargetDepartment_id = that.complaintDepartment.id
      //备注
      data.remarks = that.remarks
      //房源地址id
      data.setHouse_id = that.setHouse_id
      //房源地址id
      data.roomHouse_id = that.roomHouse_id
      //图片list
      if (that.fileList.length) {
        data.doImgList = []
        for (let i = 0; i < that.fileList.length; i++)
          data.doImgList.push({path: that.fileList[i].url})
      }
      data.sourceComplaint='appSourceComplaint'
      saveAfterRentComplaintAccept(data).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
              if (200 == response.status) {
                responseUtil.alertMsg(that, '操作成功')
                that.$router.go(-1)
              }
            })
          }
      )
    },

    goDepartment() {
      this.$router.push('SelectDepartment');
    },

    goPerson(person) {
      var titleName = '受理人员';
      if(person == 1){
        titleName = '投诉对象';
      }
      this.person = person
      this.$router.push({
        name: 'referrer',
        query: {
          type: 1,
          titleName:titleName
        }
      });
    },

    //初始化数据
    initData() {
      const that = this
      let initData = {}
      initData.id = that.id
      afterRentComplaintAcceptInit(initData).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
              if (response.data.data.data) {
                let complaintInfo = response.data.data.data
                //房源地址
                that.roomDetailedAddress = complaintInfo.roomDetailedAddress ? complaintInfo.roomDetailedAddress : '暂无'
                that.roomHouse_id = complaintInfo.roomHouse_id || ''
                that.setHouse_id = complaintInfo.setHouse_id || ''
                that.complaintUserName = complaintInfo.complaintUserName
                that.complaintMobile = complaintInfo.complaintMobile
                that.complaintTime = complaintInfo.addTime
                that.complaintDetail = complaintInfo.complaintDetail

              }
              if(response.data.data.list[0]){
                let acceptComplaintsInfo = response.data.data.list[0]
                //受理人员
                that.$set(that.acceptor, 'name', acceptComplaintsInfo.staffName || '')
                that.$set(that.acceptor, 'id', acceptComplaintsInfo.acceptStaff_id || '')
                //投诉对象
                that.$set(that.respondent, 'name', acceptComplaintsInfo.complaintTargetGlobaluserName || '')
                that.$set(that.respondent, 'id', acceptComplaintsInfo.complaintTargetGlobaluser_id || '')
                //投诉类型
                that.$set(that.selectType, 'dictionaryTitle', acceptComplaintsInfo.complaintTypeStr || '')
                that.$set(that.selectType, 'id', acceptComplaintsInfo.complaintType_id || '')
                //投诉等级
                that.$set(that.selectName, 'dictionaryTitle', acceptComplaintsInfo.complaintLevelStr || '')
                that.$set(that.selectName, 'id', acceptComplaintsInfo.complaintLevel_id || '')
                //投诉部门
                that.$set(that.complaintDepartment, 'title', acceptComplaintsInfo.departmentName || '')
                that.$set(that.complaintDepartment, 'id', acceptComplaintsInfo.complaintTargetDepartment_id || '')
                //备注
                that.remarks = acceptComplaintsInfo.remarks
              }

            })
          }
      )
      initData.user_id = globaluserId()
      initData.complaint_id = that.id
      queryComplaintHistory(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          for(let i in response.data.data.data)
            switch(response.data.data.data[i].complaintStatus) {
              case '1':
                that.acceptanceLists.push(response.data.data.data[i]);
                break;
              case '2':
              case '3':
                that.historyList.push(response.data.data.data[i]);
                break;
            }
        })
      })

    },
    initDictionaryData: function () {
      var that = this
      let initData = {}
      //initData.user_id = getStaffId()
      initData.dbName = ['complaintType', 'complaintLevel']
      initData.fdName = ['SOLVESTSTUSMAP']
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.complaintTypeArr = response.data.data.complaintType
          that.acceptanceArr = response.data.data.complaintLevel
        })
      })
    },

//返回上一页
    leftReturn() {
      this.$router.go(-1);
    },
// 检查是否已选择受理人员
    clickacceptanceNameShow() {
      this.isNameShow = !this.isNameShow;
      var panel = document.getElementById('acceptance')
      document.addEventListener('click', e => {
        if (!panel.contains(e.target)) {
          this.isNameShow = false
        }
      })
    }
    ,
// 检查是否已选择投诉类型
    clickcomplaintTypeShow() {
      this.isTypeShow = !this.isTypeShow;
      var panel = document.getElementById('complaintType')
      document.addEventListener('click', e => {
        if (!panel.contains(e.target)) {
          this.isTypeShow = false
        }
      })
    }
    ,
// 人员选项
    selectNameOption(item, index) {
      if(item){
        this.selectName = item
        this.acceptanceIndex = index
      }
      this.isNameShow = false
      this.isNameSelect = true
    }
    ,
// 类型选项
    selectTypeOption(item, index) {
      if(item){
        this.selectType = item
        this.complaintTypeIndex = index
      }
      this.isTypeShow = false
      this.isTypeSelect = true

    }
    ,
//日期选择组件 点击确认键后执行方法
    endPeriodConfirm(value) {
      var date = new Date(value)
      this.contractInfo.endPeriod = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    }
    ,
    clickCheckInDateShow() {
      this.isCheckInDateShow = !this.isCheckInDateShow;
      var panel = document.getElementById('checkInDatePanel')
      if (panel) {
        document.addEventListener('click', e => {
          if (!panel.contains(e.target)) {
            this.isCheckInDateShow = false
          }
        })
      }
    }
    ,
//日期选择组件 点击确认键后执行方法
    checkInDateConfirm(value) {
      this.checkInDate = value
      this.isCheckInDateShow = false
    }
    ,
//日期选择组件 点击取消键后执行方法
    checkInDateCancel() {
      this.isCheckInDateShow = false
    }
    ,
  }
}
</script>

<style lang="less" scoped>
@redius: 8px;
@spanColor: rgba(102, 102, 102, 1);
.nav_bar_title {
  z-index: 99;
}
.part {
  margin: 15px;
}
.otherExpenses-inputpart-row-bottom {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.part-subtitle {
  color: #ff5d3b;
  font-size: 12px;
  font-weight: 900;
  position: relative;
  top: 8px;
}
.traversalRecord-inputpart-column {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 15px 15px 0px;
  border-radius: 8px;
  justify-content: space-between;
  background-color: #ffffff;
  margin-top: 15px;
  margin-left: 25px;
  font-size: 12px;
}
.traversalRecord {
  float: left;
  position: relative;
}

.redRing {
  width: 11px;
  height: 11px;
  border: 1px #ff5d3b solid;
  border-radius: 50%;
  position: absolute;
  top: 35px;
}

.part-inputpart-row-graytime {
  position: absolute;
  right: 25px;
  color: #d8d8d8;
}

.redDot {
  padding: 3.5px;
  border-radius: 50%;
  background-color: #ff5d3b;
  position: absolute;
  top: 2px;
  left: 2px;
}

.redVertical-downline {
  background-color: #FF5D3B;
  width: 1.5px;
  height: 88px;
  left: 4.5px;
  top: 11px;
  position: absolute;
}

.redVertical-upline {
  background-image: linear-gradient(to bottom, white, #FF5D3B);
  width: 1.5px;
  height: 42px;
  left: 4.5px;
  bottom: 11px;
  position: absolute;
}
.part-inputpart-row-right-rightArrow {
  width: 8px;
}

.complaintInfo {
  white-space: nowrap;
  width: 48px;
  height: 17px;
  color: #FF5D3B;
  font-size: 12px;
  font-weight: bold;
  margin: 10px 0 5px 15px;
}

/*投诉信息列表*/
.complaintInfoList {
  margin: 0 15px 0 15px;
  padding-top: 10px;
  height: 170px;
  background-color: white;
  border-radius: 10px;
}

.roomDetailedAddress {
  width: 310px;
  height: 50px;
  margin-left: 15px;
  font-size: 16px;
  font-weight: bold;
}

/*房屋地址*/
.roomAddress {
  float: left;
  width: 275px
}

/*编辑图标*/
.edit_icon {
  height: 16px;
  width: 16px;
  float: right;
  margin-right: 5px;
  margin-top: 8px;
}

.line {
  width: 100%;
  margin: 5px 0 10px 0;
  border-bottom: 1px solid #f8f8f8;
}

/*受理信息*/
.acceptanceInfo {
  white-space: nowrap;
  float: left;
  width: 48px;
  height: 17px;
  color: #FF5D3B;
  font-size: 12px;
  font-weight: bold;
  margin: 20px 0 5px 15px;
}

.acceptanceList {
  float: left;
  margin-top: 2px;
}

/*受理记录跟进条*/
.followLine {
  float: left;
  width: 13px;
  height: 70px;
  margin-left: 15px;
}

.followLine2 {
  float: left;
  width: 13px;
  height: 40px;
  margin-left: 15px;
}

.rank {
  margin-bottom: 5px;
  margin-left: 10px;
  line-height: 35px;
  height: 30px;
  font-size: 16px;
  font-weight: bold;
  float: left;
}

/*受理记录时间*/
.recordTime {
  width: 130px;
  float: right;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
  color: gray;
}

/*受理记录人*/
.recordName {
  float: left;
  margin-left: 10px;
  font-size: 13px;
  width: 100%;
  height: 30px;
  font-weight: bold;
}

/*受理记录人备注*/
.explain {
  margin-left: 10px;
  font-size: 12px;
  color: gray;
}

.listLeft {
  float: left;
  width: 140px;
  /*height: 24px;*/
  line-height: 24px;
}

.listRight {
  font-size: 14px;
  display: flex;
  /*width: 170px;*/
  line-height: 24px;
}

.list {
  font-size: 14px;
  margin-left: 15px;
  /*width: 152px;*/
  font-weight: bold;
}

.label {
  width: 315px;
  height: 24px;
  font-size: 14px;
  margin-left: 14px;
  line-height: 24px;
  font-weight: bold;
}

/*span标签样式*/
.sp {
  font-size: 13px;
  margin-left: 4px;
  margin-right: 4px;
  color: #7d7b7b;
  font-weight: normal;
}

/*选择名称的div*/
.part-inputpart-row {
  float: left;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  margin: 0 5px 15px 15px;
}

.row2 {
  float: left;
  position: relative;
  flex-direction: row;
  align-items: center;
  background-color: white;
  width: 345px;
  border-radius: 8px;
  margin: 0 5px 15px 15px;
}

/*受理备注*/
.acceptanceRemake {
  margin: 10px 0 8px 10px;
  font-size: 14px;
  font-weight: bold;
}

/*备注输入框*/
.remake {
  color: gray;
  width: 310px;
  border-radius: 10px;
  line-height: 20px;
  height: 70px;
  margin: 5px 10px 0 10px;
  background-color: #f8f8f8;
  font-size: 12px;
  border: none;
  padding-left: 10px;
  padding-top: 10px;
}

/*上传图片*/
.uploadDiv {
  margin: 10px 0;
  display: flex;
  width: 100%;
}

.upload {
  border-radius: 10px;
  height: 80px;
  width: 80px;
  background-color: #f8f8f8;
  margin-left: 12px;
  float: left;
}

.upload_icon {
  height: 35px;
  width: 35px;
  margin: 14px 22px 6px 22px;
}

.upload_font {
  height: 25px;
  font-size: 12px;
  line-height: 2px;
  margin-left: 16px;
  color: gray;
}

.row3 {
  float: left;
  position: relative;
  flex-direction: row;
  align-items: center;
  background-color: white;
  width: 300px;
  height: 70px;
  border-radius: 8px;
  margin: 0px 5px 0 15px;
}

/*圆点样式---灰色*/
.maker {
  color: #999999;
  float: left;
  font-size: 28px;
  margin: 8px 10px 10px 10px;
}

/*圆点样式---橘色*/
.orange {
  color: #ff5809;
  float: left;
  font-size: 28px;
  margin: 8px 10px 10px 10px;
}

/*竖线样式*/
.content-divide {
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
}

.divide {
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
}

/*投诉输入框样式*/
.input {
  /*width: 360px;*/
  width: 100%;
  font-size: 15px;
  border: none;
}

.part-inputpart-row-header {
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
}

.part-inputpart-row-graytext {
  color: #d8d8d8;
  //width: 100%;
  flex: auto;
  font-size: 14px;
  margin-top: 1px;
}

.part-inputpart-row-normaltext {
  color: black;
}

.X-graytext {
  color: black;
  width: 98%;
  font-size: 15px;
  margin-top: 1px;
}

.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
}

/*选择三角图标的向上向下样式*/
.part-inputpart-row-right-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.complaintTypedropDownList-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}

.complaintTypedropDownList-downArrow {
  width: 9px;
}

.part-inputpart-row-right-timepick {
  width: 20px;
  margin-right: 10px;
}

/*下拉菜单选项样式*/
.part-inputpart-dropDownList-option {
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: black;
  float: left;
  width: 345px;
  height: 35px;
  padding-top: 10px;
  margin-left: 15px;
}

.part-inputpart-dropDownList {
  width: 100%;
  position: absolute;
  z-index: 99;
  margin-top: 95px;
}

.complaintTypedropDownList-option {
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: black;
  float: left;
  width: 345px;
  height: 35px;
  padding-top: 10px;
  margin-left: 15px;
}

.complaintTypedropDownList {
  width: 100%;
  position: absolute;
  z-index: 99;
  margin-top: 155px;
}

.date-dropDownList {
  margin-left: 15px;
  width: 92%;
  margin-right: 10px;
  position: absolute;
  z-index: 99;
  margin-top: 244px;
}

/*保存按钮不可点击样式*/
.saveButton_Disable {
  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 345px;
  height: 50px;
  border-radius: 8px;
  margin: 25px 15px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}

/*保存按钮可点击样式*/
.saveButton_Enable {
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  margin: 25px 15px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}

line line_bottom {
  margin: 0;
}

.des {
  font-size: 13px;
  width: 100%;
}

textarea {
  resize: none;
}

.approve {
  margin-top: 5px;
  float: left;

  .time_line {
    .item {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      .left {
        position: relative;
        margin-right: 5px;
        display: flex;
        /*float: left;*/
        justify-content: center;
        align-items: center;
        margin-left: 15px;
        /*margin-right: 5px;*/

        .line {
          width: 1px;
          background-color: rgb(255, 75, 39);
          position: absolute;
          margin: 0;
          border-bottom: none;

          &.line_top {
            top: 0px;
            height: ceil(50%-8px);

            &.first_top {
              background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Safari 5.1 - 6.0 */
              background: -o-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Opera 11.1 - 12.0 */
              background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Firefox 3.6 - 15 */
              background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
            }
          }

          &.line_bottom {
            margin: 0;
            border-bottom: 0;
            bottom: 0;
            height: ceil(50%+10px);
          }
        }

        .point {
          position: relative;
          width: 10PX;
          height: 10PX;
          border-radius: 50%;
          border: 1px solid rgb(255, 75, 39);
          transform: translateY(-50%);
          z-index: 99;
          background-color: rgb(255, 255, 255);

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgb(255, 75, 39);
            width: 6PX;
            height: 6PX;
            border-radius: 50%;
          }
        }


      }

      .right {
        width: 320px;
        padding-bottom: 10px;

        .approve_panel {
          /*margin: 0 15px;*/
          border-radius: @redius;
          background-color: rgb(255, 255, 255);
          /*padding: 10px;*/
          height: 70px;

          p {
            /*padding-top: 10px;*/

            font-weight: bolder;
            font-size: 15px;

            &:last-of-type {
              padding-bottom: 10px;
            }

            .info {
              font-size: 14px;
              font-weight: bold;
              color: black;
              margin-right: 5px;
              margin-left: 15px;
            }

            span {
              font-weight: normal;
              color: rgb(82, 82, 82);
            }
          }
        }
      }
    }

  }
}

.small_font {
  font-size: 12px !important;
  margin-right: 15px;
  margin-top: 5px;
}

.floatR {
  float: right !important;
}

.total_color {
  color: black;
  margin: 0 0 0 15px;
  padding-top: 10px;
}

.optionSelectedColor {
  color: #ff5d3b;
}
</style>
